import capitalize from "capitalize";

const THREAD_SIZE_COMMON =
  "**Note:** Common thread sizes used by this manufacturer are %0. Your model is most likely to use one of these sizes.";
const THREAD_SIZE_INCLUDED =
  "**Note:** Matching thread size is included with the %0 isolators.";
const THREAD_SIZE_NOT_INCLUDED =
  "**Note:** The required thread size for this model is not included with the %0, but are available upon request through IsoAcoustics distributors.";
const THREAD_SIZE_UNDETERMINED = `**Note:** The %0 include the 3 most common thread sizes which will match the majority of models. Alternate size threads are available upon request through IsoAcoustics distributors.`;

const THREAD_SIZE_HELP =
  "Visit [IsoAcoustics](http://www.isoacoustics.com/threads) to learn about the %0 thread sizes and how to determine which size your model requires.";

const SUBWOOFER_DOWNFIRING =
  "The following recommendations are based on a front or side firing subwoofer. Please contact %0 if your subwoofer is downfiring.";

export const StateUtil = {
  isStep3Required: (state) => {
    return (
      StateUtil.isInputQuestionsRequired(state) || state.model.multi_orientation
    );
  },
  isInputQuestionsRequired: (state) => {
    return (
      (state.make.make_id === -1 || state.model.model_id === -1) &&
      CategoryUtil.hasInputQuestions(state.category)
    );
  },
  getDimensionsLabel: (state) => {
    //`${state.model.width} x ${state.model.height} x ${state.model.depth} inches (W x H x D)`}
    const dimensions =
      state.model.model_id === -1
        ? [
            {
              width: state.width,
              height: state.height,
              depth: state.depth,
              units: state.units,
            },
          ]
        : [
            {
              width: state.model.width,
              height: state.model.height,
              depth: state.model.depth,
              units: "i",
            },
            {
              width: Math.round(state.model.width * 2.54 * 10) / 10,
              height: Math.round(state.model.height * 2.54 * 10) / 10,
              depth: Math.round(state.model.depth * 2.54 * 10) / 10,
              units: "cm",
            },
          ];

    return dimensions
      .map(
        (dimensions, index) =>
          `${CategoryUtil.getDimensionsLabel(state.category, dimensions)} ${
            dimensions.units === "i" ? "inches" : "cm"
          }${
            index === 0
              ? ` (${CategoryUtil.getDimensionsLabel(state.category)})`
              : ""
          }`
      )
      .join("\r\n");
  },
  getOrientationLabel: (state) => {
    return state.orientation || "";
  },
  getWeightLabel: (state) => {
    return state.model.model_id === -1
      ? `${state.weight} ${state.units === "i" ? "lbs" : "kg"}`
      : `${state.model.weight || 0} lbs (${
          Math.round((state.model.weight / 2.20462) * 10) / 10
        } kg)`;
  },
  isThreaded: (state) => {
    return state.model.model_id === -1 ? state.threaded : state.model.threaded;
  },
  getThreadedLabel: (state) => {
    return StateUtil.isThreaded(state) ? "Yes" : "No";
  },
  isThreadSizeUndetermined: (state) => {
    return (
      state.model.model_id === -1 ||
      (state.model.thread_sizes || []).length === 0
    );
  },
  getThreadedSizeLabel: (state) => {
    return StateUtil.isThreadSizeUndetermined(state)
      ? "Undetermined"
      : state.model.thread_sizes
          .map((threadSize) => {
            return threadSize.description;
          })
          .join(", ");
  },
  isCommonThreadSizesAvailable: (state) => {
    return (
      state.make.make_id !== -1 && (state.make.thread_sizes || []).length > 0
    );
  },
  getCommonThreadSizeLabel: (state) => {
    const threadSizes = (state.make.thread_sizes || [])
      .map((threadSize) => {
        return threadSize.description;
      })
      .join(", ");
    return THREAD_SIZE_COMMON.replace("%0", threadSizes);
  },
  getThreadSizeDescription: (state, product) => {
    return product.threaded && !StateUtil.isCategoryCeilingWall(state)
      ? state.model.model_id === -1 ||
        (state.model.thread_sizes || []).length === 0
        ? [
            THREAD_SIZE_UNDETERMINED.replace("%0", product.name),
            THREAD_SIZE_HELP.replace("%0", product.name),
          ].join("\r\n\r\n")
        : state.model.thread_sizes.some((threadSize) => {
            return !!product.thread_sizes.find((_threadSize) => {
              return _threadSize.thread_size_id === threadSize.thread_size_id;
            });
          })
        ? THREAD_SIZE_INCLUDED.replace("%0", product.name)
        : [
            THREAD_SIZE_NOT_INCLUDED.replace("%0", product.name),
            THREAD_SIZE_HELP.replace("%0", product.name),
          ].join("\r\n\r\n")
      : "";
  },

  isCategorySubwoofer: (state) => {
    return state.category.code === "subwoofer";
  },
  isCategoryCeilingWall: (state) => {
    return state.category.code === "pro_ceiling_wall";
  },
  isMakeOther: (state) => {
    return state.make.make_id === -1;
  },
  isModelOther: (state) => {
    return state.model.model_id === -1;
  },
  isOrientationRequired: (state) => {
    return CategoryUtil.hasInputQuestionOrientation(state.category);
  },
  isWeightRequired: (state) => {
    return CategoryUtil.hasInputQuestionWeight(state.category);
  },
  isThreadedRequired: (state) => {
    return CategoryUtil.hasInputQuestionThreaded(state.category);
  },
  getDetails: (state, recommendations) => {
    // used by results page to populate details section;
    //  also used by product inquiry to send email
    let details = {
      category: CategoryUtil.getCategoryName(state.category, state.locale_id),
      make: StateUtil.isMakeOther(state)
        ? `Other: ${state.makeName}`
        : state.make.name,
      model: StateUtil.isModelOther(state)
        ? `Other: ${state.modelName}`
        : state.model.name,
      dimensions: StateUtil.getDimensionsLabel(state),
      orientation:
        (StateUtil.isModelOther(state) &&
          StateUtil.isOrientationRequired(state)) ||
        state.model.multi_orientation
          ? capitalize(StateUtil.getOrientationLabel(state))
          : null,
      weight: StateUtil.isWeightRequired(state)
        ? StateUtil.getWeightLabel(state)
        : null,
      threaded: StateUtil.isThreadedRequired(state)
        ? StateUtil.getThreadedLabel(state)
        : null,
      "thread size": null,
      note: null,
      additional: null,
    };

    if (details.threaded !== null) {
      details["thread size"] = StateUtil.isThreaded(state)
        ? StateUtil.getThreadedSizeLabel(state)
        : null;
    }

    if (details["thread size"]) {
      details.note =
        StateUtil.isThreadSizeUndetermined(state) &&
        StateUtil.isCommonThreadSizesAvailable(state)
          ? StateUtil.getCommonThreadSizeLabel(state)
          : null;
    }

    if (StateUtil.isCategorySubwoofer(state) && StateUtil.isModelOther(state)) {
      const email = recommendations
        ? StateUtil.getDownfiringEmail(state, recommendations)
        : null;

      details.additional = SUBWOOFER_DOWNFIRING.replace(
        "%0",
        email
          ? `[IsoAcoustics](mailto:info@isoacoustics.com?subject=${email.subject}&body=${email.body})`
          : "IsoAcoustics"
      );
    }

    return details;
  },
  getDetailsVerbose: (state) => {
    let details = StateUtil.getDetails(state);
    let labels = Object.getOwnPropertyNames(details);
    return labels
      .filter((label) => details[label] !== null)
      .map((label) => {
        let newline = ["model", "thread size"].includes(label) ? "\r\n" : "";
        let tabs = label.length + 1 > 8 ? "\t" : "\t\t";
        return `${capitalize.words(label)}:${tabs}${details[label]
          .replace("**Note:** ", "")
          .replace(/\[(.*?)\]\((.+?)\)/g, "$1") // https://regex101.com/r/tZ6yK9/1
          .replace(/(\r\n)/g, "\r\n\t\t\t")}${newline}`;
      })
      .join("\r\n");
  },
  getDownfiringEmail: (state, recommendations) => {
    // [IsoAcoustics](mailto:info@isoacoustics.com?subject=IsoAcoustics%20Downfiring%20Subwoofer%20Inquiry)
    return {
      subject: encodeURIComponent("IsoAcoustics Downfiring Subwoofer"),
      body: StateUtil.getEmailBody(state, recommendations),
    };
  },
  getInquiryEmail: (state, recommendations) => {
    return {
      subject: encodeURIComponent("IsoAcoustics Product Inquiry"),
      body: StateUtil.getEmailBody(state, recommendations),
    };
  },
  getEmailBody: (state, recommendations) => {
    return encodeURIComponent(
      [
        "[Submit email to IsoAcoustics to review. Please include your Name and Country. Feel free to attach photos and provide details.]",
        "",
        "Recommendations (" + recommendations.length + ")",
        "----------------------------",
        recommendations
          .map((recommendation) => {
            return ProductUtil.getRecommendationLabel(recommendation);
          })
          .join("\r\n"),
        "",
        "Details",
        "----------------------------",
        StateUtil.getDetailsVerbose(state),
      ].join("\r\n")
    );
  },
};

export const CategoryUtil = {
  getCategoryLocale: (category, locale_id) => {
    const locale = (category && category.locales ? category.locales : []).find(
      (locale) => {
        return locale.locale_id === locale_id;
      }
    );
    return locale;
  },
  getCategoryName: (category, locale_id) => {
    const locale = CategoryUtil.getCategoryLocale(category, locale_id);
    return locale ? locale.name : "-";
  },
  hasInputQuestions: (category) => {
    return category.input_questions && category.input_questions.length > 0;
  },
  hasInputQuestion: (category, code) => {
    return !!(category.input_questions || []).find((inputQuestion) => {
      return inputQuestion.code === code;
    });
  },
  hasInputQuestionWidth: (category) => {
    return CategoryUtil.hasInputQuestion(category, "width");
  },
  hasInputQuestionHeight: (category) => {
    return CategoryUtil.hasInputQuestion(category, "height");
  },
  hasInputQuestionDepth: (category) => {
    return CategoryUtil.hasInputQuestion(category, "depth");
  },
  hasInputQuestionOrientation: (category) => {
    return CategoryUtil.hasInputQuestion(category, "orientation");
  },
  hasInputQuestionWeight: (category) => {
    return CategoryUtil.hasInputQuestion(category, "weight");
  },
  hasInputQuestionThreaded: (category) => {
    return CategoryUtil.hasInputQuestion(category, "threaded");
  },
  getDimensionsLabel: (category, dimensions) => {
    return ["width", "height", "depth"]
      .reduce((list, dimension) => {
        if (CategoryUtil.hasInputQuestion(category, dimension)) {
          if (dimensions) {
            list.push(dimensions[dimension]);
          } else {
            list.push(dimension.charAt(0).toUpperCase());
          }
        }
        return list;
      }, [])
      .join(" x ");
  },
};

export const ProductUtil = {
  getProductLocale: (product, locale_id) => {
    const locale = (product && product.locales ? product.locales : []).find(
      (locale) => {
        return locale.locale_id === locale_id;
      }
    );
    return locale;
  },
  getProductDescription: (product, locale_id) => {
    const locale = ProductUtil.getProductLocale(product, locale_id);
    return locale ? locale.description : "-";
  },
  getRecommendationLabel: (recommendation) => {
    return `${recommendation.name} ${
      recommendation.quantities.length
        ? `(QTY ${recommendation.quantities.join(",")})`
        : ""
    }`;
  },
};

export const AdapterUtil = {
  getAdapterLocale: (adapter, locale_id) => {
    const locale = (adapter && adapter.locales ? adapter.locales : []).find(
      (locale) => {
        return locale.locale_id === locale_id;
      }
    );
    return locale;
  },
  getAdapterDescription: (adapter, locale_id) => {
    const locale = AdapterUtil.getAdapterLocale(adapter, locale_id);
    return locale ? locale.description : "-";
  },
};
