import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import axios from "axios";

import {
  ActionButton,
  DefaultButton,
  FontSizes,
  FontWeights,
  Label,
  Link,
  PrimaryButton,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Stack,
  Text,
} from "office-ui-fabric-react";

import { ErrorDialog } from ".";
import { AdapterUtil, ProductUtil, StateUtil } from "../utils";
import { useStateContext } from "../state";

function Results(props) {
  const [state] = useStateContext();
  const [recommendations, setRecommendations] = useState(null);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(StateUtil.getDetails(state));

  const isMakeOther = StateUtil.isMakeOther(state);
  const isModelOther = StateUtil.isModelOther(state);
  const isOrientationRequired = StateUtil.isOrientationRequired(state);

  useEffect(() => {
    (async () => {
      try {
        let parameters = [
          ["category", state.category.category_id].join("="),
          [
            "make",
            encodeURIComponent(isMakeOther ? state.makeName : state.make.name),
          ].join("="),
          [
            "model",
            encodeURIComponent(
              isModelOther ? state.modelName : state.model.name
            ),
          ].join("="),
          ["units", state.units].join("="),
        ];

        if (
          !isModelOther &&
          !isOrientationRequired &&
          state.model.multi_orientation
        ) {
          parameters.push(
            ["orientation", encodeURIComponent(state.orientation || "")].join(
              "="
            )
          );
        }

        (state.category.input_questions || []).forEach((inputQuestion) => {
          parameters.push(
            [
              inputQuestion.code,
              encodeURIComponent(
                state[inputQuestion.code] !== undefined
                  ? state[inputQuestion.code]
                  : ""
              ),
            ].join("=")
          );
        });

        if (state.referrer) {
          parameters.push(
            ["referrer", encodeURIComponent(state.referrer)].join("=")
          );
        }

        let response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/recommendations?${parameters.join("&")}`
        );


        const recommendations = response.data.reduce(
          (list, recommendation) => {
            let product = list.find((product) => {
              return product.product_id === recommendation.product_id;
            });
            if (!product) {
              product = recommendation;
              product.quantities = [];
              list.push(product);
            }
            if (typeof recommendation.quantity === "number") {
              product.quantities.push(recommendation.quantity);
            }
            return list;
          },
          []
        );

        setRecommendations(recommendations);
      } catch (e) {
        setError(
          e.response && e.response.data ? e.response.data.message : e.message
        );
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (recommendations != null) {
      setDetails(StateUtil.getDetails(state, recommendations));
    }
  }, [state, recommendations]);

  return (
    <div className={props.className}>
      <ErrorDialog
        error={error}
        onClickCloseErrorDialog={() => {
          setError(null);
        }}
      />
      <Stack tokens={{ childrenGap: 5 }}>
        <Text
          styles={{
            root: { marginTop: "30px !important", fontSize: FontSizes.small },
          }}
        >
          RESULTS
        </Text>
        <div style={{ position: "absolute", top: "15px", right: "0px" }}>
          <ActionButton
            className="print-hidden"
            iconProps={{ iconName: "Print" }}
            disabled={!recommendations}
            onClick={() => {
              window.print();
            }}
          >
            Print
          </ActionButton>
        </div>

        <Text className="media-header">Summary</Text>
        <Text
          styles={{
            root: { marginTop: "20px !important", fontSize: FontSizes.small },
          }}
        >
          The following details are what we based our recommendations on.
        </Text>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <Label
                    styles={{
                      root: {
                        padding: "0px",
                        marginRight: "15px",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    Category
                  </Label>
                </td>
                <td style={{ width: "100%" }}>
                  <Text>{details.category}</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Label
                    styles={{
                      root: {
                        padding: "0px",
                        marginRight: "15px",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    Make
                  </Label>
                </td>
                <td>
                  <Text>{details.make}</Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Label
                    styles={{
                      root: {
                        padding: "0px",
                        marginRight: "15px",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    Model
                  </Label>
                </td>
                <td>
                  <Text>{details.model}</Text>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <Label
                    styles={{
                      root: {
                        padding: "0px",
                        marginRight: "15px",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    Dimensions
                  </Label>
                </td>
                <td>
                  {details.dimensions.split("\r\n").map((dimensions, index) => {
                    return (
                      <Text key={index} block>
                        {dimensions}
                      </Text>
                    );
                  })}
                  {}
                </td>
              </tr>
              {details.orientation !== null ? (
                <tr>
                  <td>
                    <Label
                      styles={{
                        root: {
                          padding: "0px",
                          marginRight: "15px",
                          whiteSpace: "nowrap",
                        },
                      }}
                    >
                      Orientation
                    </Label>
                  </td>
                  <td>
                    <Text block>{details.orientation}</Text>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {details.weight !== null ? (
                <tr>
                  <td>
                    <Label
                      styles={{
                        root: {
                          padding: "0px",
                          marginRight: "15px",
                          whiteSpace: "nowrap",
                        },
                      }}
                    >
                      Weight
                    </Label>
                  </td>
                  <td>
                    <Text block>{details.weight}</Text>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {details.threaded !== null ? (
                <>
                  <tr>
                    <td>
                      <Label
                        styles={{
                          root: {
                            padding: "0px",
                            marginRight: "15px",
                            whiteSpace: "nowrap",
                          },
                        }}
                      >
                        Threaded
                      </Label>
                    </td>
                    <td>
                      <Text block>{details.threaded}</Text>
                    </td>
                  </tr>
                  {details["thread size"] !== null ? (
                    <>
                      <tr>
                        <td>
                          <Label
                            styles={{
                              root: {
                                padding: "0px",
                                marginRight: "15px",
                                whiteSpace: "nowrap",
                              },
                            }}
                          >
                            Thread Size
                          </Label>
                        </td>
                        <td>
                          <Text block>{details["thread size"]}</Text>
                        </td>
                      </tr>
                      {details.note !== null ? (
                        <>
                          <tr>
                            <td colSpan="2">
                              <Markdown
                                source={details.note}
                                className="react-markdown"
                              />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {details.additional !== null ? (
                <>
                  <tr>
                    <td colSpan="2">
                      <Markdown
                        source={details.additional}
                        className="react-markdown"
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Text className="media-header">
            {recommendations
              ? `Recommendations (${recommendations.length})`
              : "Recommendations"}
          </Text>
          {recommendations == null ? (
            <RecommendationsShimmer />
          ) : (
            <>
              {!isModelOther && state.model.comments ? (
                <Text style={{ display: "block", padding: "15px 5px 5px 5px" }}>
                  {state.model.comments}
                </Text>
              ) : null}
              <RecommendationList recommendations={recommendations} />
            </>
          )}
        </div>
        <div className="wizard-step-button-container print-hidden">
          <DefaultButton
            style={{ marginRight: "5px", verticalAlign: "top" }}
            onClick={() => {
              if (StateUtil.isStep3Required(state)) {
                props.previousStep();
              } else {
                props.goToStep(3);
              }
            }}
            text="Back"
          />
          <DefaultButton
            iconProps={{ iconName: "Refresh" }}
            onClick={() => props.goToStep(2)}
            text="Start Over"
          />
        </div>
      </Stack>
    </div>
  );
}

function RecommendationsShimmer() {
  return (
    <div className="recommendationShimmerContainer">
      <Shimmer
        className="recommendationShimmer"
        customElementsGroup={
          <div style={{ display: "flex" }}>
            <ShimmerElementsGroup
              flexWrap={true}
              width="100%"
              shimmerElements={[
                {
                  type: ShimmerElementType.line,
                  width: "40%",
                  height: 20,
                },
                { type: ShimmerElementType.gap, width: "60%", height: 30 },
                { type: ShimmerElementType.line, width: "100%", height: 20 },
                { type: ShimmerElementType.line, width: "65%", height: 20 },
                { type: ShimmerElementType.gap, width: "35%", height: 30 },
                { type: ShimmerElementType.line, width: "75%", height: 20 },
                { type: ShimmerElementType.gap, width: "25%", height: 30 },
              ]}
            />
            <ShimmerElementsGroup
              shimmerElements={[
                { type: ShimmerElementType.gap, width: 10, height: 120 },
                { type: ShimmerElementType.line, width: 250, height: 120 },
              ]}
            />
          </div>
        }
      ></Shimmer>
    </div>
  );
}

function RecommendationList({recommendations}) {
  const [state] = useStateContext();

  const titleTextStyles = {
    root: {
      color: "#025F52",
      fontWeight: FontWeights.semibold,
    },
  };
  const descriptionTextStyles = {
    root: {
      color: "#333333",
      fontWeight: FontWeights.regular,
    },
  };
  const helpfulTextStyles = {
    root: {
      display: "block",
      fontWeight: FontWeights.regular,
      fontSize: "14px",
      marginTop: "1em",
    },
  };

  return recommendations.map((recommendation, index) => {
    const images = (recommendation.images || []).sort((a, b) => {
      return a.order - b.order;
    });
    return (
      <div key={index} className="recommendationContainer print-nobreak">
        <Text variant="large" styles={titleTextStyles}>
          {ProductUtil.getRecommendationLabel(recommendation)}
        </Text>
        <div className="recommendationInnerContainer">
          <div className="recommendationTextContainer">
            <Text styles={descriptionTextStyles}>
              {[
                StateUtil.getThreadSizeDescription(state, recommendation),
                ...(recommendation.adapters || []).map(function (
                  adapter,
                  index
                ) {
                  return (
                    <Adapter
                      name={adapter.name}
                      description={AdapterUtil.getAdapterDescription(
                        adapter,
                        state.locale_id
                      )}
                    ></Adapter>
                  );
                }),
                ProductUtil.getProductDescription(
                  recommendation,
                  state.locale_id
                ),
              ]
                .filter((description) => {
                  return description !== "";
                })
                .map((description, index) => {
                  return typeof description === "object" ? (
                    description
                  ) : (
                    <>
                      <Markdown
                        key={index}
                        source={description}
                        renderers={{ link: LinkRenderer }}
                      />
                    </>
                  );
                })}
            </Text>
          </div>
          <div className="recommendationImageContainer">
            <img
              className="recommendationImage"
              src={
                images.length > 0
                  ? `data:${recommendation.images[0].mime_type};base64,${recommendation.images[0].content}`
                  : ""
              }
              alt={recommendation.name}
            />
          </div>
        </div>
        <PrimaryButton
          text="Discover"
          onClick={() => {
            window.open(
              recommendation.dealer_product_uri || recommendation.uri
            );
          }}
          className="print-hidden"
        ></PrimaryButton>
        <Link
          className="print-hidden"
          styles={helpfulTextStyles}
          onClick={() => {
            const email = StateUtil.getInquiryEmail(state, recommendations);
            window.location.href = `mailto:info@isoacoustics.com?subject=${
              email.subject
            }&body=${email.body}&cc=${recommendation.dealer_email || ""}`;
          }}
        >
          Additional Questions?
          <br />
          Submit to IsoAcoustics to review.
        </Link>
      </div>
    );
  });
}

function LinkRenderer(props) {
  return (
    <Link href={props.href} target="_blank">
      {props.children}
    </Link>
  );
}

function Adapter(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Link
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? "-" : "+"} Required Adapter:
      </Link>{" "}
      {props.name}
      {expanded ? (
        <p style={{ marginTop: "5px", paddingLeft: "10px" }}>
          {props.description}
        </p>
      ) : null}
    </div>
  );
}

export default Results;
