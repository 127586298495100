import React, { useState, useEffect } from "react";
import { useStateContext, StateProvider } from "./state";
import { Welcome, Step1, Step2, Step3, Results } from "./components";
import Measure from "react-measure";
import logo from "./logo.svg";
import "./App.css";

import {
  css,
  initializeIcons,
  loadTheme,
  mergeStyleSets,
  Fabric,
  Stack
} from "office-ui-fabric-react";
import { NeutralColors } from "@uifabric/fluent-theme";

// Load Custom Fabric UI Theme
initializeIcons();
loadTheme({
  defaultFontStyle: {
    fontFamily: "Quicksand, sans-serif",
    fontWeight: "regular"
  },
  palette: {
    themePrimary: "#148647",
    themeLighterAlt: "#f2faf6",
    themeLighter: "#ccecda",
    themeLight: "#a3dbbc",
    themeTertiary: "#5ab784",
    themeSecondary: "#269658",
    themeDarkAlt: "#127a41",
    themeDark: "#0f6737",
    themeDarker: "#0b4c28",
    neutralLighterAlt: "#f8f8f8",
    neutralLighter: "#f4f4f4",
    neutralLight: "#eaeaea",
    neutralQuaternaryAlt: "#dadada",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c8c8",
    neutralTertiary: "#bab8b7",
    neutralSecondary: "#a3a2a0",
    neutralPrimaryAlt: "#8d8b8a",
    neutralPrimary: "#323130",
    neutralDark: "#605e5d",
    black: "#494847",
    white: "#ffffff"
  }
});

const handleResize =
  window.parent !== window && window.parent.postMessage
    ? () => {
        window.parent.postMessage(
          document.getElementById("measure-bounds").getBoundingClientRect(),
          "*"
        );
      }
    : () => {};

function App() {
  const initialState = {
    locale_id: 1,
    referrer: (() => {
      if (document.referrer) {
        var anchor = document.createElement("a");
        anchor.href = document.referrer;
        return anchor.host;
      } else {
        return "";
      }
    })(),

    // step 1
    category: null,

    // step 2
    make: null,
    makeName: "",
    model: null,
    modelName: "",

    // step 3
    units: "i",
    width: "",
    height: "",
    depth: "",
    orientation: "",
    weight: "",
    threaded: null
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "changeStep":
        return {
          ...state,
          step: action.step
        };
      case "changeCategory":
        return {
          ...state,
          category: action.category
        };
      case "changeMake":
        return {
          ...state,
          make: action.make,
          makeName: "",
          model:
            action.make && action.make.make_id === -1 ? { model_id: -1 } : null,
          modelName: ""
        };
      case "changeMakeName":
        return {
          ...state,
          makeName: action.name
        };
      case "changeModel":
        return {
          ...state,
          model: action.model,
          modelName: "",
          orientation:
            action.model &&
            action.model.default_orientation &&
            action.model.default_orientation.code
              ? action.model.default_orientation.code
              : state.orientation
        };
      case "changeModelName":
        return {
          ...state,
          modelName: action.name
        };
      case "changeUnits":
        let _state = {
          ...state,
          units: action.units
        };
        switch (_state.units) {
          case "i":
            ["width", "height", "depth"].forEach(dimension => {
              if (_state[dimension]) {
                _state[dimension] =
                  Math.round((parseFloat(_state[dimension]) / 2.54) * 100) /
                    100 +
                  "";
              }
            });
            if (_state.weight) {
              _state.weight =
                Math.round(parseFloat(_state.weight) * 2.2 * 100) / 100 + "";
            }
            break;
          case "m":
            ["width", "height", "depth"].forEach(dimension => {
              if (_state[dimension]) {
                _state[dimension] =
                  Math.round(parseFloat(_state[dimension]) * 2.54 * 100) / 100 +
                  "";
              }
            });
            if (_state.weight) {
              _state.weight =
                Math.round((parseFloat(_state.weight) / 2.2) * 100) / 100 + "";
            }
            break;
          default:
            break;
        }
        return _state;
      case "changeWidth":
        return {
          ...state,
          width: action.width
        };
      case "changeHeight":
        return {
          ...state,
          height: action.height
        };
      case "changeDepth":
        return {
          ...state,
          depth: action.depth
        };
      case "changeOrientation":
        return {
          ...state,
          orientation: action.orientation
        };
      case "changeWeight":
        return {
          ...state,
          weight: action.weight
        };
      case "changeThreaded":
        return {
          ...state,
          threaded: action.threaded
        };
      default:
        return state;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => (
          <div ref={measureRef} id="measure-bounds">
            <Fabric className="fabric">
              <Stack className="wizard-container">
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: "0 0 1px 0",
                    borderColor: NeutralColors.gray50
                  }}
                >
                  <img src={logo} className="logo" alt="logo" />
                </div>
                <Wizard
                  steps={[
                    { id: "Welcome", component: <Welcome /> },
                    { id: "Step1", component: <Step1 /> },
                    { id: "Step2", component: <Step2 /> },
                    { id: "Step3", component: <Step3 /> },
                    { id: "Results", component: <Results /> }
                  ]}
                ></Wizard>
              </Stack>
            </Fabric>
          </div>
        )}
      </Measure>
    </StateProvider>
  );
}

const styles = mergeStyleSets({
  "wizard-step-hidden": {
    display: "none"
  }
});

function Wizard(props) {
  const [step, setStep] = useState(1);
  const [state, dispatch] = useStateContext();

  const previousStep = () => {
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };
  const goToStep = _step => {
    setStep(_step);
  };

  useEffect(() => {
    if (step === 2 && state.make) {
      dispatch({ type: "changeMake", make: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div className="wizard">
      {props.steps.reduce((steps, _step, i) => {
        let currentIndex = step - 1;
        if (i <= currentIndex) {
          steps.push(
            React.cloneElement(_step.component, {
              key: _step.id,
              className: css(
                "wizard-step",
                i !== currentIndex ? styles["wizard-step-hidden"] : ""
              ),
              previousStep: previousStep,
              nextStep: nextStep,
              goToStep: goToStep
            })
          );
        }
        return steps;
      }, [])}
    </div>
  );
}

export default App;
