import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  DefaultButton,
  FontSizes,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Shimmer,
  ShimmerElementType,
  Stack,
  Text
} from "office-ui-fabric-react";

import { ErrorDialog } from ".";
import { CategoryUtil } from "../utils";
import { useStateContext } from "../state";

function Step1(props) {
  const [state] = useStateContext();
  const [categories, setCategories] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (categories === null) {
      (async () => {
        try {
          let response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/categories`,
            {
              params: {
                referrer: state.referrer
              }
            }
          );

          setCategories(response.data);
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <div className={props.className}>
      <ErrorDialog
        error={error}
        onClickCloseErrorDialog={() => {
          setError(null);
        }}
      />
      <Stack tokens={{ childrenGap: 5 }}>
        <div
          style={{
            position: "relative",
            marginTop: "30px",
            fontSize: FontSizes.small
          }}
        >
          <Text
            styles={{
              root: { verticalAlign: "top", fontSize: "inherit" }
            }}
          >
            EQUIPMENT TYPE
          </Text>
        </div>
        <Text className="media-header">Choose a category</Text>
        <Text
          styles={{
            root: { marginTop: "20px !important", fontSize: FontSizes.small }
          }}
        >
          Lets start by selecting the type of audio equipment.
        </Text>
        <div style={{ marginTop: "20px" }}>
          {categories == null ? (
            <CategoriesShimmer />
          ) : categories.length === 0 ? (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
            >
              The referring dealer does not appear to have any product
              categories.
            </MessageBar>
          ) : (
            <Categories
              categories={categories}
              onDoubleClick={props.nextStep}
            />
          )}
        </div>
        <div className="wizard-step-button-container">
          <DefaultButton
            style={{ marginRight: "5px" }}
            onClick={props.previousStep}
            text="Back"
          />
          <PrimaryButton
            onClick={props.nextStep}
            text="Next"
            disabled={!state.category}
          />
        </div>
      </Stack>
    </div>
  );
}

function CategoriesShimmer() {
  return (
    <Shimmer
      className="categoryShimmer"
      shimmerElements={[
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.line }
      ]}
    ></Shimmer>
  );
}

function Categories(props) {
  const categories = props.categories;
  const [state, dispatch] = useStateContext();

  return (
    <React.Fragment>
      {categories.map(category => {
        const name = CategoryUtil.getCategoryName(category, state.locale_id);

        return (
          <div
            key={category.category_id}
            className={
              "user-select-none category " +
              (state.category &&
              state.category.category_id === category.category_id
                ? "selected"
                : "")
            }
            onClick={() => {
              dispatch({ type: "changeCategory", category: category });
            }}
            onDoubleClick={props.onDoubleClick}
          >
            <img
              src={
                category.image
                  ? `data:${category.image.mime_type};base64,${category.image.content}`
                  : ""
              }
              alt={name}
            />
            <Text
              styles={{
                root: {
                  fontSize: FontSizes.small
                }
              }}
            >
              {name}
            </Text>
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default Step1;
