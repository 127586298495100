import React from "react";
import {
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton
} from "office-ui-fabric-react";

export default props => {
  return (
    <Dialog
      hidden={!props.error}
      dialogContentProps={{
        type: DialogType.close,
        title: "Error",
        subText: props.error || ""
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 450 } }
      }}
    >
      <DialogFooter>
        <PrimaryButton onClick={props.onClickCloseErrorDialog} text="Ok" />
      </DialogFooter>
    </Dialog>
  );
};
