import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  mergeStyles,
  ComboBox,
  DefaultButton,
  FontSizes,
  Label,
  PrimaryButton,
  Shimmer,
  ShimmerElementType,
  Stack,
  Text,
  TextField
} from "office-ui-fabric-react";

import { ErrorDialog } from ".";
import { CategoryUtil, StateUtil } from "../utils";
import { useStateContext } from "../state";

function Step2(props) {
  const [state, dispatch] = useStateContext();
  const [makes, setMakes] = useState(null);
  const [models, setModels] = useState(null);
  const [error, setError] = useState(null);

  const isMakeSelected = !!state.make;
  const isMakeOther = isMakeSelected && state.make.make_id === -1;
  const isModelSelected = !!state.model;
  const isModelOther = isModelSelected && state.model.model_id === -1;
  const isMakeModelValid =
    isMakeSelected &&
    (!isMakeOther || state.makeName) &&
    isModelSelected &&
    (!isModelOther || state.modelName);

  useEffect(() => {
    if (makes === null) {
      (async () => {
        try {
          let responses = await Promise.all([
            axios.get(
              `${process.env.REACT_APP_API_URL}/api/categories/${state.category.category_id}`
            ),
            axios.get(
              `${process.env.REACT_APP_API_URL}/api/makes?category_id=${state.category.category_id}`
            )
          ]);

          dispatch({
            type: "changeCategory",
            category: responses[0].data
          });
          setMakes(
            responses[1].data.sort((a, b) => {
              return (a.name || "").localeCompare(b.name);
            })
          );
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makes]);

  useEffect(() => {
    if (isMakeSelected && !isMakeOther && models === null) {
      (async () => {
        try {
          let response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/models?category_id=${state.category.category_id}&make_id=${state.make.make_id}`
          );

          setModels(
            response.data.sort((a, b) => {
              return (a.name || "").localeCompare(b.name);
            })
          );
        } catch (e) {
          setError(
            e.response && e.response.data ? e.response.data.message : e.message
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMakeSelected, isMakeOther, models]);

  const _onChangeMake = (event, option) => {
    setModels(null);
    dispatch({
      type: "changeMake",
      make: option
        ? option.key === -1
          ? {
              make_id: -1,
              name: ""
            }
          : makes.find(make => {
              return make.make_id === option.key;
            })
        : null
    });
  };

  const _onChangeMakeTextField = (event, text) => {
    dispatch({
      type: "changeMakeName",
      name: text
    });
  };

  const _onChangeModel = (event, option) => {
    dispatch({
      type: "changeModel",
      model: option
        ? option.key === -1
          ? {
              model_id: -1,
              name: ""
            }
          : models.find(model => {
              return model.model_id === option.key;
            })
        : null
    });
  };

  const _onChangeModelTextField = (event, text) => {
    dispatch({
      type: "changeModelName",
      name: text
    });
  };

  const wrapperClassName = mergeStyles({
    selectors: {
      ":not(:first-child)": { marginTop: "20px" },
      "& > *": { marginBottom: "20px" },
      "& .ms-ComboBox, & .ms-TextField-fieldGroup": { maxWidth: "350px" }
    }
  });

  const categoryName = CategoryUtil.getCategoryName(
    state.category,
    state.locale_id
  );

  return (
    <div className={props.className}>
      <ErrorDialog
        error={error}
        onClickCloseErrorDialog={() => {
          setError(null);
        }}
      />
      <Stack tokens={{ childrenGap: 5 }}>
        <Text
          styles={{
            root: { marginTop: "30px !important", fontSize: FontSizes.small }
          }}
        >
          EQUIPMENT MANUFACTURER
        </Text>

        <Text className="media-header">Select your make and model</Text>
        <Text
          styles={{
            root: { marginTop: "20px !important", fontSize: FontSizes.small }
          }}
        >
          Choose "Other" if your make or model is not in our list.
        </Text>

        <div className={wrapperClassName}>
          <div>
            {makes ? (
              <ComboBox
                label="Make"
                placeholder="Select or type the make"
                autoComplete="on"
                allowFreeform={true}
                useComboBoxAsMenuWidth={true}
                options={makes
                  .concat([{ make_id: -1, name: "Other" }])
                  .map(function (make) {
                    return { key: make.make_id, text: make.name };
                  })}
                onChange={_onChangeMake}
                selectedKey={isMakeSelected ? state.make.make_id : null}
              />
            ) : (
              <React.Fragment>
                <Label disabled={true}>Make</Label>
                <Shimmer
                  className="ms-ComboBox comboBoxShimmer"
                  shimmerElements={[{ type: ShimmerElementType.line }]}
                ></Shimmer>
              </React.Fragment>
            )}
            <TextField
              placeholder={`Enter your ${categoryName.toLowerCase()} make`}
              onChange={_onChangeMakeTextField}
              value={state.makeName}
              styles={{
                root: {
                  display: isMakeOther ? "" : "none",
                  paddingTop: "5px"
                }
              }}
            ></TextField>
          </div>
          {!isMakeSelected || isMakeOther || models ? (
            <div>
              <ComboBox
                label="Model"
                placeholder={isMakeSelected ? "Select or type the model" : ""}
                useComboBoxAsMenuWidth={true}
                disabled={!isMakeSelected}
                options={
                  models
                    ? models
                        .concat([{ model_id: -1, name: "Other" }])
                        .map(function (model) {
                          return { key: model.model_id, text: model.name };
                        })
                    : []
                }
                onChange={_onChangeModel}
                selectedKey={isModelSelected ? state.model.model_id : null}
                styles={{
                  root: {
                    display: isMakeOther ? "none" : ""
                  }
                }}
              />
              <TextField
                placeholder={`Enter your ${categoryName.toLowerCase()} model`}
                onChange={_onChangeModelTextField}
                value={state.modelName}
                styles={{
                  root: {
                    display: isMakeOther || isModelOther ? "" : "none",
                    paddingTop: !isMakeOther && isModelOther ? "5px" : "0px"
                  }
                }}
              ></TextField>
            </div>
          ) : (
            <React.Fragment>
              <Label disabled={true}>Model</Label>
              <Shimmer
                className="ms-ComboBox comboBoxShimmer"
                shimmerElements={[{ type: ShimmerElementType.line }]}
              ></Shimmer>
            </React.Fragment>
          )}
        </div>
        <div className="wizard-step-button-container">
          <DefaultButton
            style={{ marginRight: "5px" }}
            onClick={props.previousStep}
            text="Back"
          />
          <PrimaryButton
            onClick={() => {
              if (StateUtil.isStep3Required(state)) {
                props.nextStep();
              } else {
                props.goToStep(5);
              }
            }}
            text="Next"
            disabled={!isMakeModelValid}
          />
        </div>
      </Stack>
    </div>
  );
}

export default Step2;
