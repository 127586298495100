import React from "react";

import { FontSizes, Icon, Link, Stack, Text } from "office-ui-fabric-react";

function Welcome(props) {
  return (
    <div className={props.className}>
      <Stack tokens={{ childrenGap: 5 }}>
        <Text
          styles={{
            root: { marginTop: "30px !important", fontSize: FontSizes.small }
          }}
        >
          PRODUCT SELECTION WIZARD
        </Text>
        <Text className="media-header">
          Let us help you find the right product
        </Text>
        <div style={{ margin: "35px 0 10px 0" }}>
          <Link onClick={props.nextStep}>
            Get started{" "}
            <Icon
              iconName="ChevronRight"
              styles={{ root: { position: "relative", top: "2px" } }}
            />
          </Link>
        </div>
      </Stack>
    </div>
  );
}

export default Welcome;
